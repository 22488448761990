import React, { useEffect } from "react";
import styles from "./styles.css";
import PreApprovalMeter from "./images/pre-approval-meter.svg";
import Button from "../../shared/button";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPreApprovalLoanDetails, setPreApprovedOrigin } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
// import FinancingCfExpired from "../pre-approval-financing-flow/financing-cf-expired";
// import { LOAN_ERROR } from "../../../constants/checkout-constants";
import { BANNER_TYPE, CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../tracking";
import TrackImpression from "../../shared/track-impression";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";

const GetPreApprovalCardLanding = ({
    isLoggedIn,
    getPreApprovalLoanDetailsConnect,
    preApproved,
    // financeErrorResponse,
    setPreApprovedOriginConnect,
    isUserZeroDpVariant,
    showOnlyCTA = false,
    isVariant,
    bannerType = ""
}) => {
    // const { errorCode } = financeErrorResponse || {};
    // const [isModalopen, setIsModalOpen] = useState(false);
    const history = useHistory();

    const trackEvents = () => {
        switch (bannerType) {
        case BANNER_TYPE.FINANCE_BANNER:
            return  trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.FINANCE_BANNER_GET_PRE_APPROVAL_CTA);
        default:
            return  trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PREAPPROVAL_APPLY_NOW, {
                eventLabel: "NA"
            });
        }
    };

    const handleClick = () => {
        // if (errorCode === LOAN_ERROR.EXPIRED) {
        //     setIsModalOpen(true);
        // } else {
        setPreApprovedOriginConnect(CAR_LIST_EVENT_NAMES.HOME_PAGE);
        trackEvents();
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.GET_PRE_APPROVAL, {
            "Source": "Homepage"
        });
        history.push("/financing/get-pre-approval");
        //}
    };

    const getData = async () => {
        try {
            await getPreApprovalLoanDetailsConnect();
        } catch (error) {
            // console.log(error);
        }
    };

    const handleFinanceKnowMore = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NUDGES_FINANCE_KNOW_MORE_FINANCE, {
            eventLabel: "Home_page"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.KNOW_MORE_FINANCE, {
            "Know_more": "Home_page"
        });
        history.push("/cars24-finance");
    };

    useEffect(() => {
        if (isLoggedIn) {
            getData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    if (showOnlyCTA) {
        return (
            <React.Fragment>
                {!preApproved && <button styleName={"styles.button"} onClick={handleClick}>Get pre-approval</button>}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <TrackImpression event={{name: "financePreapprovalViewd"}}>
                {!preApproved && <div styleName={`styles.wrapper ${!isVariant ?  "" : "styles.variant"}`}>
                    <img src={PreApprovalMeter} alt="Get pre-approval" />
                    <div>
                        {/* <h3>Get pre-approval</h3> */}
                        <h3>{isUserZeroDpVariant ? "$0 deposit loans" : "Get pre-approved"}</h3>
                        {/* <p>as <span>little as 2 min</span> to see personalised financing terms</p> */}
                        {isUserZeroDpVariant ?  <p>Get pre-approved in <span>2 mins</span></p> :
                            <p>for finance in <span>2 mins</span></p>}
                    </div>
                    <div styleName={"styles.buttonWrapper"}>
                        <Button text={"GET PRE-APPROVAL"} onClick={handleClick}/>
                        <p styleName={"styles.knowMore"} onClick={handleFinanceKnowMore}>Know more about<br />finance</p>
                    </div>
                </div>}
            </TrackImpression>
            {/* <FinancingCfExpired fromModal={true} isModalopen={isModalopen} handleCloseModal={() => setIsModalOpen(false)} /> */}
        </React.Fragment>
    );
};
GetPreApprovalCardLanding.propTypes = {
    isLoggedIn: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    getPreApprovalLoanDetailsConnect: PropTypes.func,
    setPreApprovedOriginConnect: PropTypes.func,
    preApproved: PropTypes.bool,
    showOnlyCTA: PropTypes.bool,
    financeErrorResponse: PropTypes.object,
    isVariant: PropTypes.bool,
    bannerType: PropTypes.string
};

const mapStateToProps = ({
    user: { isLoggedIn, isUserZeroDpVariant },
    preApprovalLoan: {
        preApproved,
        financeErrorResponse
    }
}) => ({
    isLoggedIn,
    preApproved,
    financeErrorResponse,
    isUserZeroDpVariant
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getPreApprovalLoanDetailsConnect: getPreApprovalLoanDetails,
    setPreApprovedOriginConnect: setPreApprovedOrigin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GetPreApprovalCardLanding);
